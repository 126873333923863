

.card-body
{
    background-color: var(--white);
    position: relative;
}

select, .form-control
{
    width: 24em;
}

.OptionSwitch
{
    width: fit-content;
    display: inline-block;
}

label
{
    width: 16em;
    vertical-align: middle;
    font-weight: 600;
}

.form-control
{
    display: inline-block;
    margin-bottom: .5em;
}

.right-align
{
    text-align: right;
    width: 7em;
}

#SaveButton
{
    position: absolute;
    right: 1em;
    bottom: 1em;
}

#percentSym
{
    width:fit-content;
}

.currency
{
    display: inline-block;
    position: relative;
}

.currency::before
{
    position: absolute;
    top: 20%;
    content:"$";
    left: 5px;
}

.percentage
{
    display: inline-block;
    position: relative;
}

.percentage::after
{
    position: absolute;
    top: 20%;
    content:"%";
    right: 15px;
}

.percentage:hover::after, .percentage:focus-within::after
{
    display: none;
}
