//
// datatable.scss
//

.datatables {
    thead {
        tr {
            th {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 7px;
                    font: normal normal normal 24px/1 "Material Design Icons";
                    opacity: 0.3;
                }

                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 14px;
                    opacity: 0.3;
                    font: normal normal normal 24px/1 "Material Design Icons";
                }
            }

            .asc {
                &:before {
                    opacity: 1;
                }
            }

            .desc {
                &:after {
                    opacity: 1;
                }
            }
        }
    }
}