//
// forms.scss
//

@import '~simplemde/dist/simplemde.min.css';

// Form-control light
.form-control-light {
    background-color: $gray-100 !important;
    border-color: $gray-100 !important;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}


// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

select.form-control {
    &:not([size]):not([multiple]) {
        height: $input-height;
    }
}

select.form-control-sm {
    &:not([size]):not([multiple]) {
        height: $input-height-sm;
    }
}


.ck-editor__editable {
    min-height: 245px !important;
}

.ck.ck-toolbar {
    background-color: $gray-200 !important;
    border: 1px solid $gray-300 !important;
}

.ck.ck-editor__main>.ck-editor__editable {
    border-color: $gray-300 !important;
    box-shadow: none !important;
}

.ck.ck-dropdown__panel,
.ck.ck-list {
    background: $dropdown-bg !important;
    border: 1px solid $dropdown-border-color !important;
}