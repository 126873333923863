//
// Select2.scss
//

@import "~vue-multiselect/dist/vue-multiselect.min.css";

.multiselect__tags {
    min-height: $input-height !important;
    padding: $input-padding-y $input-padding-x !important;
    font-family: $font-family-base !important;
    @include font-size($input-font-size);
    font-weight: $input-font-weight !important;
    line-height: $input-line-height !important;
    color: $input-color !important;
    background-color: $input-bg !important;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color !important;
}

.multiselect__option--highlight,
.multiselect__option--highlight:after {
    background: $primary !important;
}

.multiselect__placeholder {
    padding-top: 0 !important;
    margin-bottom: 0 !important;
}

.multiselect__input {
    margin-bottom: 0 !important;
}

.multiselect__single {
    padding-left: 0 !important;
    margin-bottom: 0 !important;
}

.multiselect__tag {
    background-color: $primary !important;
    margin-bottom: 0 !important;
}

.multiselect__tag-icon:after {
    color: $gray-100 !important;
}

.multiselect,
.multiselect__input,
.multiselect__single {
    font-size: 15px !important;
}




