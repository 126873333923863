
.settingsLabel
{
    display:table-cell;
    width:fit-content;
    padding:5px;
    vertical-align: top;
}

.settingsDiv
{
    display:table-cell;
    width:fit-content;
    padding:5px;
    vertical-align: text-top;
}

.OptionSwitch
{
    vertical-align: top !important;
}

.TableHeader
{
    font-weight: bold;

}

td
{
    padding: 12px 0 12px 0;
}
table
{
    margin-left: 12px;
    margin-right: 12px;
    width: auto;
}

