
.lockCard {
  min-width: 1200px;
}

.alignCenter {
  align-self: center;
}

#flowDropdown .multiselect__tags,
#stateDropdown .multiselect__tags {
  padding-right: 25px !important;
}

.form-group {
  margin-bottom: 0.5rem;
}

#resetButton.btn-secondary,
#resetButton.btn-secondary:focus,
#resetButton.btn-secondary:visited {
  color: rgb(58, 58, 58);
  background-color: #bfd9f1;
  border-color: #bfd9f1;
}

#resetButton.btn-secondary:hover,
#resetButton.btn-secondary:active {
  color: rgb(58, 58, 58);
  background-color: #a0c7eb;
  border-color: #a0c7eb;
}

// #filterButton.buttonColor,#filterButton.buttonColor:focus {
//   background-color: #F89C43;
//   border-color: #F89C43;
// }

// #filterButton.buttonColor:active,#filterButton.buttonColor:hover {
//   background-color: #f1902f;
//   border-color: #f1902f;
// }

// #filterButton {
//     bottom: 0;
//     right: 0;
//     position: absolute;
//     margin-right: 12px;
// }

#buttonContainer {
  bottom: 0;
  right: 0;
  position: absolute;
  margin-right: 12px;
}

#firstName.col-9,
#lastName.col-9 {
  flex: 0 0 71.5%;
  max-width: 71.5%;
}

#addressContainer.col-10 {
  flex: 0 0 84.253333%;
  max-width: 84.253333%;
  min-width: 475px;
}

#addressLabel.col-2,
#postalCodeLabel.col-2 {
  flex: 0 0 13.666667%;
  max-width: 13.666667%;
  min-width: 79px;
}

#inputPostalCode {
  max-width: 105px;
}

#cityContainer.col-10 {
  flex: 0 0 75.663333%;
  max-width: 75.663333%;
}

// #postalContainer.col-2 {
//   flex: 0 0 16.6666666667%;
//   max-width: 16.6666666667%;
// }

.paddingFix {
  padding-left: 1.04rem;
}
