

.OptionSwitch
{
    width: fit-content;
    display: inline-block;
}

label
{
    width: 10em;
    vertical-align: middle;
    font-weight: 600;
    margin: 0;
}

.form-control
{
    width: 24em;
    display: inline-block;
    margin-bottom: .5em;
}

.settingRow
{
    display: flex;
    border: 1px solid rgba($color: #000000, $alpha: 0.0);
    align-items: center;
}

#SaveButton
{
    position: absolute;
    right: 1em;
    bottom: 1em
}
