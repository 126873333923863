
#statusContainer {
  padding-right: 3px;
}#previousButton,
#previousButton i,
#nextButton,
#nextButton i {
  border-radius: 25px;
  height: 30px;
  width: 30px;
  line-height: 30px;
}

#previousPageContainer :hover,
#nextPageContainer :hover {
  background-color: #e9ecef;
}

.alignCenter {
  align-self: center;
}

.lockCard {
  min-width: 1200px;
}

.addressList {
  list-style-type: none;
}

.smallList {
  padding-inline-start: 0px;
  margin-bottom: 0px;
}

#reportCard .btn-secondary, 
#reportCard .btn-secondary:focus, 
#reportCard .btn-secondary:visited {
  color: rgb(58, 58, 58);
  background-color: #bfd9f1;
  border-color: #bfd9f1;
}

#reportCard .btn-secondary:hover,
#reportCard .btn-secondary:active {
  color: rgb(58, 58, 58);
  background-color: #a0c7eb;
  border-color: #a0c7eb;
}

.smallPara {
  margin-bottom: 0px;
}

// #reportTable td {
//   vertical-align: middle;
// }

#reportTable .stylePadding {
  padding-bottom: 1.25rem;
}

table#reportTable span.fflCheck {
    display: inline-block;
    margin-left: 60px;
}

table#reportTable span.fflCheckFail {
    display: inline-block;
    margin-left: 32px;
}

table#reportTable span.exemptionbullet {
    display: inline-block;
    margin-left: 25px;
}

table#reportTable span.businesstradetitle {
  //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  width: 100%;
  background-color: rgba(0,0,0,0.015);
  padding: 2px 0px 0px 3px;
}

table#reportTable span.addlrequirementmet {
  //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  width: 100%;
  margin-left: 32px;
  padding: 2px 0px 0px 3px;
}

table#reportTable span.addlFflLicenserequirementnotmet {
  //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  width: 100%;
  margin-left: 32px;
  padding: 2px 0px 0px 3px;
}

