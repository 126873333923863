

.description
{
    margin-left:16px;
    margin-bottom: 5px;
}

.left-panel 
{
    width: 240px;
    float: left;
    background-color: #ffffff;
    //height: 78vh;
    min-height: 100%;
    padding: 1em 1em 1em 1em;
}

.sideBtn
{
    width: 100%;
    text-align: left;
    margin: 0 3px 6px 0;
}

.AdminMain 
{
    margin-top:0em;
    //margin-left: 240px;
    margin-bottom: 0px !important;
    width: calc(100% - 240px);
    min-height: 77.8vh;
    background-color: #ffffff;
    border-left: 4px solid #f4f4f4;
    
    padding: 1em 1em 1em 1em;
}

::v-deep .groupSet
{
    margin: 2em 1em 24px 1em;
    padding: 2em 2em 2em 2em;
    box-shadow: 0 0 15px 0 rgb(154 161 171 / 35%);
    background-color: #ffffff;
    border-radius: 5px;
}

::v-deep input[data-switch="bool1"]:checked+label {
    background-color: #1e8e3e;
}

//from table
::v-deep .btnContainer 
{
    border: 1px solid #adb5bd;
    border-radius: 5px;
    width: 1.8em;
    text-align: center;
    font-size: 14pt;
    background-color: transparent;
    color: #adb5bd;
    margin: 12px 3px 3px 0;
    position: relative;
    top: -5px;
}

::v-deep .btnContainer:hover
{
    background-color:#adb5bd;
    color: #ffffff;
}

::v-deep .btnContainer:disabled
{
    background-color: #cccccc;
    color: #aaaaaa;
}

#container
{
    height: fit-content;
    display: flex;
}


