<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          CONFIDENTIAL {{ new Date().getFullYear() }} RICO Technologies LLC
        </div>
        <div class="col-md-6">
          <div class="text-md-right footer-links d-none d-md-block">
            <a href="mailto:support@echeckpoint.com">Contact Support</a>
            <!--//
            <a href="javascript: void(0);">About</a>
            <a href="javascript: void(0);">Contact Us</a>
            //-->
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>


<style>
body[data-layout=topnav] .footer {
    left: 0 !important;
    padding: 10px 0 10px;
    font-size: 11px;
}
</style>
