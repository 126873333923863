
@-webkit-keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }

  100% {
    background-position: 250px 0;
  }
}

table {
  width: 100%;

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    td {
      height: 50px;
      vertical-align: middle;
      padding: 8px;

      span {
        display: block;
      }

      &.td-1 {
        width: 20px;

        span {
          width: 20px;
          height: 20px;
        }
      }

      &.td-2 {
        width: 50px;

        span {
          background-color: rgba(0, 0, 0, 0.15);
          width: 50px;
          height: 12px;
        }
      }

      &.td-3 {
        width: 400px;

        // padding-right: 100px;
        span {
          height: 12px;
          background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
          background-size: 500px 100px;
          animation-name: moving-gradient;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
        }
      }

      &.td-4 {}

      &.td-5 {
        //width: 100px;

        span {
          background-color: rgba(0, 0, 0, 0.15);
          width: 100%;
          height: 30px;
        }
      }
    }
  }
}

.actions {
  display: inline-block;
  overflow-y: auto !important;
  -ms-overflow-style: none;
  scrollbar-width: none;

  // Action button Colors
  .btnContainer {
    border: 1px solid #adb5bd;
    border-radius: 5px;
    width: 1.75em;
    text-align: center;
    font-size: 14pt;
    background-color: transparent;
    color: #adb5bd;
    margin-bottom: 3px;
    margin-right: 3px;
  }

  .redBtn {
    color: #6c757d;
    border: 1px solid #6c757d;
  }

  .btnContainer:hover {
    background-color: #adb5bd;
    color: #ffffff;
  }

  .redBtn:hover {
    background-color: #6c757d;
    color: #ffffff;
  }
}

.actions::-webkit-scrollbar{ display: none;}

.parentProductNameLabel {
  margin: 20px, 0, 0, 0;
}

.newProdLabel {
  margin-right: 10px;
  padding-right: 10px;
  width: auto;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.tagCloseButton {
  height: 4px;
  width: 4px;
}

input[data-switch="success-ec-mc"]:checked+label {
  //background-color: #16a23e;
  background-color: #1e8e3e;
}

.tagCloseButton:hover {
  background-color: rgba(88, 96, 102, 0.349);
  border-radius: 50%;
}

#productTable .multiselect .multiselect__content-wrapper {
  min-width: 500px;
  width: auto;
  border-top: 1px solid #e8e8e8;
}

#productTable .multiselect--active .multiselect__tags {
  border-bottom: none;
}

.deleteLabel {
  padding: 5px;
}
