


.dateCol {
  width: 125px;
}

.statusCol {
  width: 50px;
}

.actionCol {
  width: 75px;
}


.statusTable {
  display: table;
  table-layout: fixed;
  width: 502px;
}

.TableDiv
{
  overflow-y: scroll;
}

.screenHeight
{
  max-height: 78vh;
  margin-bottom: 0;
}

.max-size {
  //max-height: 900px;
}

/*Styling for container div*/
.wrapperDiv {
  /*background-color: white;*/
  //display: grid;
  //grid-template-areas:
  //@at-root  '.sales-orders' '.right-side';
  /*box-shadow: 0 0 15px 0 rgb(154 161 171 / 15%);*/
  //position: relative;
  //z-index: 20;
  //box-shadow: inset 0 -20px 10px black;
  //clip-path: inset(0px 0px 0px 0px);
  height: 78vh;
}

/*Styling for right side container holds documenent frame, notes, and history table*/
#right-side {
  flex-basis: auto;
  overflow-y: scroll;
  padding-left: 10px;
  margin-left: 550px;
  //max-height: fit-content;
  //height: fit-content;
  height: 100%;
  //scrollbar-width: none;
  //-ms-overflow-style:none ;
  

  > :first-child
  {
    margin-bottom:0;
    padding-top: 4px;
    padding-bottom: 3px;
    //height: 78vh;
  }

  > :nth-child(2)
  {
    margin-bottom: 0;
    margin-top: 16px;
  }
}

#right-side::-webkit-scrollbar
{
  display: none ;
}

/*Styling for order info headers*/
#order-info {
  justify-content: space-between;
  margin-top: 20px;
}

#headers {
  >:first-child
  {
    width: 65%;
  }
  >:nth-child(2)
  {
    position:absolute;
    right:40px;
  }
  h5
  {
    display: inline-block;
    margin-top: 0;
  }
}

#reqInfo
{
  :first-child
  {
    width: 100%;
  }
}

/*Styling for documentation frames*/
#form-frame
{
  width: 400px;
  height: 400px;
  border: 1px solid #00000033;
  overflow: auto;
}

.status
{
  margin-left: 15px;
}

#id-frame {
  border: 1px solid #00000033;
  //border-radius: 5px;
  //min-height: 150px;
  height: 400px;
  width: 400px;//100%;

  display: flex;
  justify-content: center;
}

#fileViewer
{
  max-height: 400px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;

  //cursor: none;
}

#fileViewerZoom
{
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  //transform: translateY(-100px) translateX(500px);
  //transform-origin: center;
  //pointer-events: none;
  z-index: 5;
}

#enlargedImg
{
  position: fixed;
  background-color: #00000022;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  img
  {
    margin:auto;
    max-width: 35%;//because of the scale this is actually 70%
    max-height: 40%;//because of the scale this is actually 80%
    scale: 2;
    border: 5px solid #f5f5f5;
    border-radius: 10px;
  }

}

#ZoomedFile
{
  transform: scale( 1.5 , 1.5);
  position: relative;
}

#FileFrame
{
  width: 100%;
  //width: 400px;
  height: 100%;
  //height: 400px;
  //border-radius: 5px;
  background-color: #f5f5f5;
  position: relative;
}

#noFile
{
  width: fit-content;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}

#pdfViewer
{
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;//464px;
  border:none;
}

#FormFrame
{
  padding: 1em;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: auto;
}

.formRowLabel
{
  display: inline-block;
  width: 150px;
  text-align: end;
  margin-right: .25em;
}

/*Styling for deny and accept buttons*/
#button-container {
  text-align: center;
  padding-bottom:1em;
  > :first-child
  {
    margin-right: 10px;
    margin-left: 0;
  }
}

.button {
  margin-top: 10px;
  margin-right: 10px;
  color: #222;
  padding: 10px;
  width: 150px;
  height: 42px;
  border-radius: 10px;
  border: none;
  background-color: #BFD9F1;
}

.button:disabled
{
  color: #aaa;
}

#accept {
  background-color: #0acf97;
  color: white;
  margin-right: 0;
}

#deny {
  background-color: #fa5c7c;
  color: white;
}

#override {
  background-color: #ffbc00;
  color: white;
  margin-left: 10px;
}

#ManualOverride
{
  position: absolute;
  margin: 0;
  top: 5px;
  right: 5px;
  border: 1px solid #222;
  height: fit-content;
  width: fit-content;
  padding-top:.25em;
  padding-bottom:.25em;
  vertical-align: middle;
}

#ManualFile input
{
  filter: opacity(1);
}

.FPBtn
{
  margin-left: 10px;
  //margin-right: 10px;
  border-radius: 10px;
  height: 42px;
  //padding-bottom: 15px;
  line-height: normal;
  
}



/*Styling for Notes container */
#Notes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  textarea
  {
    resize: none;
  }
}

/*Styling for Note containers */
.box {
  height: 50px;
}

.noteArea
{
  border:1px solid #00000040;
  border-radius: 3px;
  width: 100%;
  height: 100%;
}



/*Styling Between Calendar and Expiration Date */
#order-info-header-to-calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left:0;
}

//Styling for Fulfillment Requirements
.formLabel
{
  width: 50%;
}

//date input styling
#calendar-div span {
  margin-right: 10px;
}

#floatLeft {
  float: left;
}

#floatRight {
  float: right;
}

//THIS IS WHAT I HAVE ADDED
.card
{
  padding: 1em 1em 1em 1em;
  //border: 1px solid rgba($color: #000000, $alpha: 0.0);
  height: fit-content;
}

.left-side
{
  width:550px;
  float: left;
  max-height: 78vh;
  overflow-y:scroll;
  > :first-child
  {
    height: 100%;
  }
}

.left-side::-webkit-scrollbar
{
  display: none;
}

.Subtable
{
  table-layout:fixed;
  margin: 0 auto;
  width: 465px;
}

.TableHead
{
  position:sticky;
  top: 0;
  background-color: white;
  box-shadow:0 1px rgba($color: #000000, $alpha: 0.05) ;
  z-index: 2;
  th
  {
    border: 0;
  }
}

.clickable:hover
{
  cursor: pointer;
  background-color: #eeeeee;
}

.ibtn
{
  margin-right: 4px;
  margin-bottom: 3px;
  height: 2.5em;
  width: 2.5em;
  text-align: center;
  padding: 0;
}

.label
{
  margin-right: 5px;
  width: 75px;
}

.miniLabel
{
  width:fit-content;
  margin-left: 2px;
}

#summaryFilter
{
  margin-top:5px;
  padding: 5px;
  padding-bottom:10px ;
  background-color: #fafafa;
  border-radius: 3px;
  width:519px;
}

#filterBtn
{
  background-color: #F89C43;
  border-color: #F89C43;
  color: #ffffff;
  float: right;
  margin-left: 4px;
}

#clearBtn
{
  background-color: #727cf5;
  border-color: #727cf5;
  color: #ffffff;
  float: right;
}

#pageSpan
{
  margin-right: 4px;
  font-size: 12.5pt;
}

.btnCell
{
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 5px;
}

.changeBtn
{
  margin-top: 4px;
  padding-left: 5px;
  padding-right: 5px;
}

#Name
{
  width: 170px;
}

#OrdNums
{
  width: 170px;
}

#orderLabel
{
  margin-left: 6px;
}

#endDateLabel
{
  margin-left: 46px;
}

#statusDiv
{
  margin-top: 5px;
  display: inline-block;
}

#statusLabel, .miniLabel
{
  margin-bottom: 0;
}

input
{

  border:1px solid rgba($color: #000000, $alpha: .4);
  border-radius: 2px;

  //color:rgba($color: #000000, $alpha: 0.5);
  filter: opacity(.6);
}

input[type='checkbox']:checked{
  accent-color: #727cf5 ;
  filter: opacity(1);
}

//ADRESSE AND PRODUCT INFO
#addresses
{

  vertical-align: top;
}

#products
{

  vertical-align: top;
}

#prdList
{
  list-style-type: "►";
}

.dropdown-menu
{
  position: relative !important;
  width: 100% !important;
  max-width: 400px !important;
  min-width: 1rem !important;
}

.dropdown-menu  li > a
{
  min-width: 0;
  white-space: normal;
  width: 100%;
  max-width: 400px;
}


#ColumnLeft
{
  width: 400px;
  //display: inline-block;
  //float:left;
  position: absolute;
  //float: left;
}

#addresses-and-products-ordered
{
  //width: 55%;
  min-height: 400px;
  margin-left:410px;
  display: inline-block;
  vertical-align: top;

}


#CommentTable
{
  thead tr{
    :first-child
    {
      width: 1%;
    }
    :nth-child(2)
    {
      width: 20%;
    }
    :nth-child(3)
    {
      width:10%;
      //width: fit-content;
    }
    :nth-child(4)
    {
      text-wrap: nowrap;
      width: 10%;
    }
    tbody td
    {
      vertical-align: top;
    }
  }
}

.feedback
{
  //background-color: #000000;
  height: 20px;
  position: relative;
  left: 17%;
  top: -30px;
  font-weight: bold;
  pointer-events: none;
}

@keyframes feedbackAnim 
{
  0%{top: 0px; opacity: 80%;}
  80%{top: -28px; opacity: 0%;}
  100%{top: -30px; opacity: 0%; display:none;}
}

/*.buttonFeedback
{
  animation-name: feedbackAnim;
  animation-duration: .75s;
  animation-fill-mode: forwards;
  position: relative;
}

.buttonFeedbackHide
{
  display: none;
}*/

@keyframes BtnActive
{
  50% {filter: brightness(85%);}
}

.buttonActivated
{
 //background-color:white;
  animation-name: BtnActive;
  animation-duration: 250ms;
}

.orderButton > button
{
  width: 100px;
  height: 25px;
  margin: 0;
  padding: 2px 0 0 0;
}

.orderButton {
	float: right;
    margin-left: 1em;
}

#addInternalBtn
{
  float: right;
  margin: 0;
  margin-top: 6.5px;
  padding: 2px 0 0 0;
  height: 25px;
}

#watermark
{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10%;

  width: fit-content;
  height: fit-content;

  color: #fa5c7c;
  filter:opacity(.75);
  font-size: 50px;
  pointer-events: none;

  margin-left: auto;
  margin-right: auto;
  div{
    text-align: center;
    padding-left: .25em;
    padding-right: .25em;
    border: 5px solid #fa5c7c;
    border-radius: 5px;
  }
}

//
#headers>:first-child {
  width: auto !important;
}
#headers>:nth-child(2) {
  float: right;
  margin-left: 15px;
  position: relative !important;
  right: 0 !important;
}

#reqInfo h5 {
  color: #222;
  border: 1px solid rgba(0,0,0,0.2);
  padding: 10px 10px 0px;
  text-transform: uppercase;
  background-color: #efefef;
  border-radius: 4px;
  text-align: center;
}
#reqInfo span {
  font-weight: 400;
  padding: 10px 0 5px;
  line-height: 20px;
  text-transform: none;
  display: block;
}
#addresses-and-products-ordered {
	margin-left: 415px !important;
}
#addresses-and-products-ordered h5 {
	font-size: 18px;
	margin: 10px 0 5px;
}
#addresses-and-products-ordered p {
  color: #555;
  font-weight: bold;
  margin: 0 0 2px;
}
#addresses-and-products-ordered span {
  font-weight: 400;
}
#addresses, #products {
	margin: 25px 0;
}
#addInternalBtn {
	margin: 0;
}
#clearBtn {
  background-color: #bfd9f1;
  border-color: #bfd9f1;
  color: #555;
  float: right;
}
#id-frame {
	border-radius: 4px;
}
.formRowLabel {
	font-weight: 700;
}
input {
	padding: 2px 7px;
}
#ManualOverride {
	border: 0 !important;
}
#FileFrame {
	border-radius: 4px;
}
button {
  border-radius: 4px !important;
}

#FFLScope
{
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  font-size: large;
  > button
  {
    display: inline-flex;
    align-items: center;
    width: 4.35em;
    padding-left: 1em;
    padding-right: 1em;
    margin-left: calc(50% - 2.175em);
    margin-right: 0;
  }

  > p
  {
    margin-bottom: 0;
  }
}

div #innerRight
{
  padding-bottom: 0!important;
}

.btnCell button, .btnCell > div > div > :first-child{
    padding: 0px 7px;
    font-size: 12px;
    margin: 0 0 3px 0;
    border: 1px solid #58568c;
    text-transform: capitalize;
    border-radius: 4px;	
}
button#__BVID__21__BV_toggle_ {
    margin: 0;
}
button#accept.button[disabled] {
    background-color: darkgray;
}
button#deny.button[disabled] {
    background-color: darkgray;
}
button#override.button[disabled] {
    background-color: darkgray;
}
.overriden
{
  width: fit-content;
  margin-left: 10px;
  margin-right: auto;
  margin-top: 1em;
  font-size: large;
  > button
  {
    display: inline-flex;
    align-items: center;
    width: 4.35em;
    padding-left: 1em;
    padding-right: 1em;
    margin-left: calc(50% - 2.175em);
    margin-right: 0;
  }

  > p
  {
    margin-bottom: 0;
  }
}
