.echeckpointOrange {
    background-color: #F89C43 !important;
}

.echeckpointBlue {
    background-color: #BFD9F1 !important;
    color: black !important;
}
.active > .echeckpointBlue {
    background-color: #BFD9F1 !important;
    color: black !important;
}

.echeckpointBlue.btn {
    color: black !important;
    outline-color: #bfd9f1 !important;
    box-shadow: 0px 2px 6px 0px rgb(191 217 241 / 50%) !important;
    border-color: #bfd9f1 !important;
}

.echeckpointBlue.btn:hover {
    outline-color: #a0beda !important;
    background-color: #a0beda !important;
    border-color: #a0beda !important;
}

.echeckpointOrange.btn {
    color: #ffffff;
    outline-color: #f89c43 !important;
    box-shadow: 0px 2px 6px 0px rgb(248 156 67 / 50%) !important;
    border: none;
}

.echeckpointOrange.btn:hover {
    outline-color: #e78b2f !important;
    background-color: #e78b2f !important;
    border-color: #e78b2f !important;
}

.echeckpointOrangeCheckbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #F89C43 !important;
    outline-color: #F89C43 !important;
}

.echeckpointOrangeCheckbox .custom-control-input:checked:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(255, 255, 255, 0)
}
.echeckpointOrangeCheckbox .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25)
}
.echeckpointOrangeCheckbox .custom-control-input:active ~ .custom-control-label::before {
    background-color: rgba(255, 91, 91, 0);
}

body .bouncing-loader {
    display: flex;
    justify-content: center;

    > div {
        width: 13px;
        height: 13px;
        margin: 32px 3px;
        background: #debca1;
        border-radius: 50%;
        animation: bouncing-loader 0.6s infinite alternate;

        &:nth-child(2) {
            animation-delay: 0.2s;
            background: #BFD9F1;
        }
        &:nth-child(3) {
            animation-delay: 0.4s;
            background: #F89C43;
        }
    }
}