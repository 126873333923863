
//@import "./assets/scss/app.scss";
// @import 'src/design/assets/scss/app.scss';
// @import './design/assets/scss/app.scss';
// @import './design/assets/scss/icons.scss';
// @import 'src/design/assets/scss/app.scss';

//@import 'design/assets/scss/app.scss';
//@import 'design/assets/scss/icons.scss';

// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@import "design/rico.css";
