//
// rangeslider.scss
//
.vue-slide-bar-tooltip {
  background-color: $primary !important;
  border: 1px solid $primary !important;
}

.vue-slide-bar-process {
  background-color: $primary !important;
}