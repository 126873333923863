

#iframeMain
{
    background-color: white;
    text-align: center;
    overflow: hidden;
    padding: .5em;
    width: 100% !important;
    min-width:max-content;
}

.OrderHeader
{
    text-align: left;
    width: 100%;
}

//rework
.chosen
{
    border: 1px solid #00000033;
    border-radius: 5px;
    width: 95%;
    //margin-left: auto;
    //margin-right: auto;
    margin: 8px auto 8px auto;
    padding: 1px;
    font-size: large;
    text-align: left;
    vertical-align: middle;

    :first-child
    {
        padding-left: 5px;
        width: calc(100% - 75px);
        overflow-wrap: break-word ;
    }
    > :nth-child(2)
    {
        width: fit-content;
        vertical-align: top;
        margin-top: 2px;
        //border: 1px solid;
        border: none;
        background-color: transparent;
        width: 75px;
        border-radius: 8px;
        color: inherit;
        font-size: 80%;
    }

}

.chosen div
{
    display: inline-block;
}

#ChooseTrade > :nth-child(2)
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

#ChooseExem
{
    width: 95%;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
}

#ChooseExem > :nth-child(2)
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.optionCard
{
    padding: 10px;
    border: 1px solid #00000033;
    border-radius: 5px;
    
    display: inline-block;
    position: relative;

    margin: 5px;
    flex-grow: 1;
    width: 225px;
    //min-width: 225px;
    max-width: 225px;
    height: 225px;

    animation: fadeIn .5s;

    > :first-child
    {
        font-weight: bold;
    }
    > :nth-child(2)
    {
        position: absolute;
        top: 40%;
        width: calc(100% - 20px);
        line-height: 20px;
        overflow-y: hidden;
        text-overflow: ellipsis;
    }
    > :nth-child(3)
    {
        position: absolute;
        right: .5em;
        bottom:0px;
        height: 36px;
        display: flex;
        align-items: center;
    }

    > h2
    {
        position: absolute;
        left: 0;
        right: 0;
        top:calc((100% - 1em) / 2);
        bottom:0;
        margin: auto;
        vertical-align: middle;
        text-transform: uppercase;
        pointer-events: none;
    }
}

.StatusLabel
{
    padding-top: 2px;
    display: inline-block;
    margin-right: 3px;
}

@keyframes fadeIn
{
    0% {opacity: 0;}
    100% {opacity: 1;}
}

#FormCard
{
    width: 95%;
    border: 1px solid #00000033;
    border-radius: 5px;
    //min-height: 300px;
    padding: 1em;

    margin-left: auto;
    margin-right: auto;

    animation: fadeIn .5s;

    position: relative;
}

#BackButton
{
    //width: fit-content;
    //vertical-align: top;
    //border: 1px solid;
    border: none;
    background-color: transparent;
    width: 75px;
    //border-radius: 8px;
    color: inherit;
    //font-size: 80%;

    margin-top:2px;
    position: absolute;
    right: 0;
    top:0;
}

.OptOut
{
    margin: .25em .2em .5em .2em;
    border: 1px solid #666666;
}

.OptOutMessage
{
    height: fit-content;
}

#InputForm, #FormInfo, #FormCard
{
    text-align: left;
}

#FormInfo
{
    width: 100%;
    > :first-child
    {
        width: calc(100% - 50px);
        font-size: 18px;
        font-weight: normal;
    }
    > :nth-child(2)
    {
        margin-bottom:0;
    }
    > :nth-child(3)
    {
        width: fit-content;
        cursor: pointer;
        text-decoration: underline;
        margin-bottom: 0;
    }
    > :nth-child(4)
    {
        margin-bottom: 8px;
    }
    
}

#InputForm
{
    width: fit-content;
    display: inline-block;
}

#OrderInfo
{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    > :nth-child(1)
    {
        vertical-align: top;
        width: 50%;
        display: inline-block;
        min-width: 200px;
    }
    > :nth-child(2)
    {
        vertical-align: top;
        width: 50%;
        display: inline-block;
        min-width: 250px;
    }
}

#IDmeBtn
{
    background:center / contain no-repeat url(https://s3.amazonaws.com/idme/developer/idme-buttons/assets/img/verify.svg);
    height: 44px;
    width: 220px;
    border: none;
    padding: 0;
    margin-top: 8px;
    display: block;
}

#IDmeA
{
    color: #777;
    text-decoration: underline;
    cursor: pointer;
    line-height: 12px;
    font-size: 11px;
}

#IDmeA:hover
{
    text-decoration: none;
}

button#formNextButton[disabled] {
    background-color: lightgrey;
    cursor: not-allowed;
}

button#OptOut {
    border: 0;
    padding: 7px 20px;
    margin: 10px 10px 0 0;
    background-color: #444;
    color: #fff;
    border-radius: 0;
}

button#OptOutCancel {
    border: 0;
    padding: 7px 20px;
    margin: 0 10px 0 0;
    background-color: #444;
    color: #fff;
    border-radius: 0;
}

button#OptOutConfirm {
    border: 0;
    padding: 7px 20px;
    margin: 0 10px 0 0;
    background-color: #444;
    color: #fff;
    border-radius: 0;
}

.OptOutMessage > h3 {
    margin: 40px 0 20px;
    font-family: verdana, arial, sans-serif;
    color: #333;
    font-size: medium;
}
