
.edits
{
    button
    {
        margin: 0 4px 0 8px;
    }
}

.form-block
{
    margin-bottom: 1em;
}

.User-thead
{
    :first-child
    {
        width: 200px;
        border-top: none;
    }
    :nth-child(2)
    {
        width:max-content;
        border-top: none;
    }
    /*:nth-child(3)
    {
        width: 250px;
        padding-left: 15px;
        border-top: none;
    }*/
    :nth-child(3)
    {
        width: 200px;
        padding-left: 15px;
        border-top: none;
    }
    :nth-child(4)
    {
        width: 125px;
        padding-left: 15px;
        border-top: none;
    }
    :nth-child(5)
    {
        width: 70px;
        padding-left: 0;
        border-top: none;
    }
    :nth-child(6)
    {
        width: 75px;
        padding-left: .5em;
        border-top: none;
        text-align: center;
    }
}

.API-thead
{
    :first-child
    {
        width: 200px;
        border-top: none;
    }
    :nth-child(2)
    {
        min-width: 60px;
        border-top: none;
    }
    :nth-child(3)
    {
        width: 90px;
        padding-left: 15px;
        border-top: none;
    }
    :nth-child(4)
    {
        width: 200px;
        padding-left: 15px;
        border-top: none;
    }
    :nth-child(5)
    {
        width: 125px;
        padding-left: 15px;
        border-top: none;
    }
    :nth-child(6)
    {
        width: 75px;
        padding-left: 0;
        border-top: none;
    }
    :nth-child(7)
    {
        width: 75px;
        padding-left: .5em;
        border-top: none;
        text-align: center;
    }
}

td 
{
    padding: 0;
}

.ColorCell
{
    text-align: center;
}

tbody:last-child
{   
    tr
    {
        border-bottom:none;
    }
}

tbody
{
    :last-child .btnContainer
    {
        margin-left: 25% !important;
        margin-right: 25% !important;
    }
}

.tableText
{
    font-size: 12pt;
    padding-left: 15px;
}

.needed
{
    outline: 4px solid #FF6666;
}

.row-flex
{
    display: flex;
    flex-direction: row;
}

pre
{
    white-space: pre;
    margin-bottom: .25em;
    width: fit-content;
    display: flex;
}

.APIKey
{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: min-content;
}

.obscured
{
    -webkit-text-security: disc;
    -moz-text-security: disc;
}

.ignore-click
{
    pointer-events: none;
}

.card-body
{
    .form-block:last-of-type
    {
        margin-bottom: 0;
    }
}
