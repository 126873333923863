
.edits
{
    button
    {
        margin: 0 4px 0 8px;
    }
}

thead
{
    :first-child
    {
        width: 200px;
        border-top: none;
    }
    :nth-child(2)
    {
        width:max-content;
        border-top: none;
    }
    :nth-child(3)
    {
        width: 250px;
        padding-left: 15px;
        border-top: none;
    }
    :nth-child(4)
    {
        width: 150px;
        padding-left: 15px;
        border-top: none;
    }
    :nth-child(5)
    {
        width: 250px;
        padding-left: 0;
        border-top: none;
    }
    :nth-child(6)
    {
        width: 75px;
        padding-left: .5em;
        border-top: none;
        text-align: center;
    }
}

td 
{
    padding: 0;
}

.ColorCell
{
    text-align: center;
}

tbody:last-child
{   
    tr
    {
        border-bottom:none;
    }
}

.tableText
{
    font-size: 12pt;
    padding-left: 15px;
}

.needed
{
    outline: 4px solid #FF6666;
}

.row-flex
{
    display: flex;
    flex-direction: row;
}

pre
{
    white-space: pre;
    margin-bottom: .25em;
    width: fit-content;
    display: flex;
}

.CopyBtn
{
    display:flex;
    height: 1.8em;
    margin-left: 1em !important;
}
